import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ServicosHeader from "../components/ServicosHeader";
import ListaServicos from "../components/ListaServicos";
import Slogan from "../components/Slogan";
import Contato from "../components/Contato";

function Servicos() {
  return (
    <Layout>
      <SEO
        title="Serviços"
        description="Conheça aqui um pouco do que fazemos e dos serviços que prestamos."
      />
      <ServicosHeader />
      <ListaServicos />
      <Slogan title="Quer conhecer melhor nossos serviços?" />
      <Contato />
    </Layout>
  );
}

export default Servicos;
