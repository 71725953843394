import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const List = styled.div`
  background-color: #eaedf3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(8, 250px);
  padding: 30px 0;

  ${MEDIA} {
    padding: 0;
    grid-template-rows: repeat(8, 1fr);
  }
`;

export const Item = styled.div`
  margin: auto;
  width: 80%;
  min-height: 80%;
  display: flex;
  background-color: white;

  ${MEDIA} {
    width: 100%;
    flex-direction: column;
    height: 95%;
  }
`;

export const Image = styled.img`
  min-width: 221px;
  max-width: 221px;

  ${MEDIA} {
    width: 100%;
    max-width: 100%;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 20px;
`;

export const Title = styled.h3`
  ${MEDIA} {
    font-size: 16px;
  }
`;

export const Description = styled.p`
  ${MEDIA} {
    font-size: 16px;
  }
`;
