import styled from "styled-components";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  display: flex;
  padding: 50px 100px;
  width: 100%;
  border-bottom: solid 2px #c90225;

  ${MEDIA} {
    flex-direction: column;
    padding: 0;
    align-items: center;
    padding: 90px 0 20px 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  img {
    max-width: 400px;
  }

  ${MEDIA} {
    img {
      max-width: 100%;
    }
  }
`;

export const Info = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 15px;

  ${MEDIA} {
    margin: 20px;
  }
`;

export const Title = styled.p`
  font-size: 60px;
  color: #c90225;
  margin-bottom: 30px;

  ${MEDIA} {
    width: 250px;
    font-size: 30px;
  }
`;

export const Description = styled.p`
  font-size: 20px;

  ${MEDIA} {
    font-size: 16px;
  }
`;
