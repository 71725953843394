import empilhadeira from "../../images/cursos/empilhadeira.jpg";
import socorros from "../../images/cursos/socorros.jpg";
import consultoria from "../../images/cursos/consultoria.jpg";
import agentes from "../../images/cursos/agentes.jpg";
import auxiliar from "../../images/cursos/auxiliar.jpg";
import marketing from "../../images/cursos/marketing.jpg";
import munck from "../../images/cursos/munck.jpg";
import retroescavadeira from "../../images/cursos/retroescavadeira.jpg";

const services = [
  {
    image: empilhadeira,
    title: "CURSO DE FORMAÇÃO PARA OPERADORES DE EMPILHADEIRA",
    description:
      "Em parceria com uma das maiores transportadoras da Bahia, (a EXPRESSO NEPOMUCENO), e com o Grupo LUDESO (Grupo de Luta pelo Desenvolvimento Social), nossa empresa já realizou mais de 30 (trinta) turmas do Curso de Formação para Operadores de Empilhadeira e já qualificou mais de 300 (trezentos) profissionais nessa área.",
  },
  {
    image: socorros,
    title: "CURSO DE PRIMEIROS SOCORROS",
    description:
      "Em parceria com as organizações privadas, como a Associação de Bombeiros Civis de Catu, e algumas instituições públicas da Bahia, realizamos o Curso de Primeiro Socorros, em Camaçari, Salvador, Lauro de Freitas, Mata de São João, São Francisco do Conde, Catu e diversas outras cidades do Estado. Com aulas teóricas e práticas que, notoriamente, tem modificado a vida de todos os participantes.",
  },
  {
    image: consultoria,
    title: "CONSULTORIA E PLANEJAMENTO",
    description:
      "Um plano bem elaborado é um plano meio executado! Com essa filosofia, oferecemos o melhor da nossa equipe para atender os anseios dos nossos clientes e amigos na elaboração de seus planos de contingência e ação empresarial. Além de treinamentos especializados para que todos os colaboradores estejam sempre atualizados e capacitados para agir.",
  },
  {
    image: agentes,
    title: "CURSO DE FORMAÇÃO PARA AGENTES DE PORTARIA",
    description:
      "Em parceria com uma das mais renomadas empresas de Segurança Patrimonial da Bahia, a nossa empresa oferece o Curso de Formação para Agentes de Portaria, com extensão para monitoramento por câmera e operação de Rádio Patrulha. Uma curso que tem sido bastante procurado, por esta ser uma área em constante crescimento, graças às exigências das empresas, que visam o perfeito atendimento ao cliente.",
  },
  {
    image: auxiliar,
    title: "CURSO DE AUXILIAR ADMINISTRATIVO E SECRETARIADO",
    description:
      "Nossa empresa oferece o mais completo e atualizado Curso de Auxiliar Administrativo e Secretariado do mercado. Com conteúdo programático diferenciado, que contempla os alunos com noções básicas que vão, desde o atendimento ao cliente, até as noções básicas de matemática financeira. Algo que hoje é uma das principais exigências das empresas, que anseiam por profissionais cada vez mais capacitados.",
  },
  {
    image: marketing,
    title: "CURSO DE MARKETING E FLUXO DIGITAL",
    description:
      "Objetivando a capacitação de jovens e adultos para a evolução digital no mundo, e para o competitivo mercado da propaganda em redes sociais e na internet, oferecemos o Curso de Marketing e Fluxo Digital, com noções profundas e atualizadas desta ferramenta predestinada ao domínio dos países desenvolvidos e das empresas que querem se manter sempre na frente.",
  },
  {
    image: munck,
    title: "CURSO DE OPERADOR DE MUNCK",
    description:
      "Objetivando a capacitação dos alunos para as oportunidades no mercado de trabalho, com um material didático e aulas práticas para tornar nossos aluno um profissional de excelência no mercado de trabalho.",
  },
  {
    image: retroescavadeira,
    title: "CURSO DE OPERADOR DE RETROESCAVADEIRA",
    description:
      "Objetivando a capacitação dos alunos para as oportunidades no mercado de trabalho, com um material didático e aulas práticas para tornar nossos aluno um profissional de excelência no mercado de trabalho.",
  },
];

export default services;
