import React from "react";

import {
  List,
  Item,
  Image,
  DescriptionContainer,
  Title,
  Description,
} from "./styles";

import services from "./services";

const Service = ({ img, title, description }) => (
  <Item>
    <Image src={img} alt={title} />
    <DescriptionContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </DescriptionContainer>
  </Item>
);

function ListaServicos() {
  return (
    <List>
      {services.map(service => (
        <Service
          key={Math.random()}
          img={service.image}
          title={service.title}
          description={service.description}
        />
      ))}
    </List>
  );
}

export default ListaServicos;
