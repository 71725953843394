import React from "react";

import { Container, ImageContainer, Info, Title, Description } from "./styles";

import mapa from "../../images/escolha.svg";

function ServicosHeader() {
  return (
    <Container>
      <ImageContainer>
        <img src={mapa} alt="Equipe" />
      </ImageContainer>
      <Info>
        <Title id="sobre">Serviços e Cursos</Title>
        <Description>
          Conheça aqui um pouco do que fazemos e dos serviços que prestamos.
          Seja um dos muitos profissionais que buscam atualização e capacitação
          na <strong>Martins Treinamentos</strong>.
        </Description>
      </Info>
    </Container>
  );
}

export default ServicosHeader;
